import gql from "graphql-tag";

export const addLineItemsToCart = gql`
  mutation AddLineItemsToCart(
    $email: String!
    $cartId: String!
    $lineItemsInput: LineItemsInput!
    $createCartInfo: CreateCartInfoInput
  ) {
    addLineItemsToCart(
      email: $email
      cartId: $cartId
      lineItemsInput: $lineItemsInput
      createCartInfo: $createCartInfo
    ) {
      id
      createdAt
      lastModifiedAt
      cartPrice {
        currencyCode
      }
    }
  }
`;
