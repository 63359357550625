import gql from "graphql-tag";
import { LineRestrictions } from "./lineRestrictions";

/** @deprecated replaced with LineRestrictions */
const LineItemRestrictions = gql`
  fragment LineItemRestrictions on LineItemRestrictionsType {
    type
    sku
    category
    states
    countries
    postalRanges
    __typename
  }
`;

export const ShoppingCart = gql`
  fragment ShoppingCart on CartType {
    __typename
    id
    billUpFront
    requestedDeliveryDate
    postalCode
    preferredLanguage
    displayLanguage
    country
    coordGroupType
    coordGroupGroupable
    coordGroupUserSelected
    orderNumber
    schedulableOrder
    cartSavings
    isUserMember
    giftBoxFee
    hasGiftBox
    hasGiftFromRegistry
    hasGiftFromMemberRegistry
    giftRegistryIdList
    isDiscountPriceInItem
    rushShippable
    guest {
      userId
      ctId
      atgUserId
      cwUserId
      email
      type
      typeId
      sourceCode
      shopperRef
      sfContactId
      globalSfContactId
    }
    galleryProject {
      projectId
      opportunityId
    }
    removedItems {
      items {
        sku
        productId
        lineId
        options {
          id
          type
          value
          __typename
        }
        lineRestrictions {
          ...LineRestrictionsFragment
        }
        imageUrl
      }
      __typename
    }
    lineItems {
      restrictions {
        ...LineItemRestrictions
      }
      lineRestrictions {
        ...LineRestrictionsFragment
      }
      restrictionsApply
      giftFrom
      giftTo
      isMonogramFee
      shipVia
      shipViaCode
      isMonogrammable
      isPersonalizable
      fusionId
      monogram {
        styleId
        description
        maxLines
        fontCode
        fontColorCode
        borderCode
        lines
        fulfillmentCode
        fulfillmentBorderCode
        fontColorDisplayName
        styleDisplayName
      }
      lineId
      quantity
      maxCartQuantity
      sku
      skuType
      multiSkuId
      brand
      displayName
      monogrammable
      monogramFees {
        EUR
        CAD
        GBP
        USD
      }
      giftBoxDetail {
        giftBoxFee
        giftBoxCurrencyCode
      }
      giftBoxFees {
        EUR
        CAD
        GBP
        USD
      }
      giftWrap
      deliveryEta
      deliveryEtaStart
      deliveryEtaEnd
      dc
      coordGroup
      registryId
      spoTerms
      spoTermsAccepted
      groundFreightExempt
      spoTermsAcceptedDate
      isMembershipSku
      productId
      price {
        itemPrice
        memberPrice
        originalMemberPrice
        priceStatus
        priceType
        currencyCode
        originalPrice
        totalPrice
        salePrice
        finalPrice
        salePriceLabel
        isOnClearance
        isOnSale
        showMemberPrice
        fees
        itemShippingSurcharge
        discounts {
          code
          discountAmount
          name
          key
          discountCode
          description
        }
      }
      options {
        id
        type
        value
        sortPriority
      }
      isGiftFromRegistry
      linkedLineItemId
      skuCustomization {
        type
        mountType
        lining
        panel
        controlType
        controlPosition
        controlLength
        tiltType
        controlAndTilt
        trim
        width
        length
        diameter
        roomLabel
        bracketColor
        rollType
        fulfillmentCode
      }
      childSafety
      availabilityStatus
      imageUrl
    }
    shipVia
    cartPrice {
      subtotal
      tax
      taxDetail {
        amount
        name
        country
        state
        imposition
        rate
      }
      totalPrice
      itemTotal
      feeTotal
      currencyCode
      isFree
      fees {
        feeType
        quantity
        value
        unitCost
        linkedLineItemId
        state
      }
      freight {
        unlimited
        ground
        next
        second
        shippingSurcharge
      }
      priceAdjustments {
        adjustment
        adjustmentType
        reason
        targetType
        adjustedPrice
        priceBeforeAdjustment
      }
      discounts {
        discountCodeTotal
      }
      preBillAmount
      remainingToBill
      memberSavings
      employeeSavings
    }
    soldToAddress {
      addressLine1
      addressLine2
      city
      state
      country
      companyName
      postalCode
      eveningPhone
      phone
      email
      firstName
      lastName
    }
    shipAddress {
      addressLine1
      addressLine2
      city
      state
      country
      postalCode
      phone
      eveningPhone
      email
      firstName
      lastName
      companyName
    }
    billAddress {
      addressLine1
      addressLine2
      city
      state
      country
      postalCode
      phone
      eveningPhone
      email
      firstName
      lastName
      companyName
    }
    payments {
      totalAmountRemaining
      totalAmountCovered
      appliedPayments {
        id
        paymentMethodType
        paymentType
        lastFour
        amountCovered
        currencyCode
        rhPaymentPlan
        catchAllPayment
        primaryPayment
        transactions {
          amount
          status
          type
        }
        giftCardBalance
        customerEmail
        customerPhone
        paymentRequestSendByQR
        paymentRequestSendByEmail
        paymentRequestSendByTextMessage
        transactionIdentification
      }
    }
    createdAt
    lastModifiedAt
    totalLines
    discountCodes {
      code
      name
      description
    }
    brand
    gallery {
      ctId
      designerCart
      galleryId
      key
      orderClassification
    }
    shipLabelSidemark
    giftMessage
    employeeDiscount {
      description
      regularDiscount
      saleDiscount
    }
    orgId
    containsMembership
  }
  ${LineItemRestrictions}
  ${LineRestrictions}
`;
