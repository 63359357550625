import gql from "graphql-tag";
import { Cart } from "graphql-client-cart-broker/fragments/cart";

export const queryGetCart = gql`
  query GetCart(
    $cartId: String!
    $email: String
    $siteId: String!
    $requestType: String
    $userType: String
    $currencyCode: String
  ) {
    getCart(
      cartId: $cartId
      email: $email
      requestType: $requestType
      userType: $userType
      currencyCode: $currencyCode
      siteId: $siteId
    ) {
      ...Cart
    }
  }
  ${Cart}
`;
